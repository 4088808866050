import { useCallback } from 'react';

export const useHandlers = (
  handleActiveAnimation: (
    event:
      | React.KeyboardEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLAnchorElement>,
  ) => void,
  disabled?: boolean,
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void,
) => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      !disabled && onClick && onClick(event);
      disabled && event.preventDefault();
    },
    [disabled, onClick],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLAnchorElement>) =>
      event.key === 'Enter' && handleActiveAnimation(event),
    [handleActiveAnimation],
  );

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      handleActiveAnimation(event);
      event.preventDefault();
    },
    [handleActiveAnimation],
  );

  return {
    handleClick,
    handleKeyDown,
    handleMouseDown,
  };
};
