import React from 'react';

import CareersItem, { CareersItemProps } from './CareersItem';

import './styles.scss';

type CareersSectionProps = {
  description?: {
    description: string | null;
  } | null;
  edges: readonly { node: CareersItemProps }[];
  heading?: string | null;
};

const CareersSection: React.FunctionComponent<CareersSectionProps> = ({
  description,
  edges,
  heading,
}) => {
  return (
    <section className="careers">
      <div className="container">
        {heading && <h2>{heading}</h2>}
        {description && description.description && (
          <p>{description.description}</p>
        )}
        <div>
          {edges &&
            edges.map((career: { node: CareersItemProps }, index: number) => {
              return (
                <CareersItem key={`careers-item-${index}`} {...career.node} />
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default React.memo(CareersSection);
