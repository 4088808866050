import React from 'react';

import { defaultImage, parseQuality } from 'modules/lazyload';
import { getExtension, getFormat } from 'modules/lazyload/utils';

type SourceProps = {
  lazyload?: boolean;
  local?: boolean;
  media?: string;
  quality?: string | null;
  srcSet?: string;
};

const Source: React.FunctionComponent<SourceProps> = ({
  lazyload,
  local,
  media,
  quality,
  srcSet,
}) => {
  return getExtension(srcSet) === 'svg' ? (
    <source
      media={media}
      srcSet={lazyload ? srcSet : defaultImage}
      type="image/svg+xml"
    />
  ) : (
    <React.Fragment>
      <source
        media={media}
        srcSet={
          lazyload ? getFormat('avif', local, srcSet, quality) : defaultImage
        }
        type="image/avif"
      />
      <source
        media={media}
        srcSet={
          lazyload ? getFormat('webp', local, srcSet, quality) : defaultImage
        }
        type="image/webp"
      />
      <source
        media={media}
        srcSet={
          lazyload ? `${srcSet}${parseQuality(local, quality)}` : defaultImage
        }
        type={`image/${getExtension(srcSet)}`}
      />
    </React.Fragment>
  );
};

export default React.memo(Source);
