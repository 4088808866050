import { useCallback, useEffect, useState } from 'react';

import {
  avifSrc,
  getExtension,
  imageSupported,
  mediaQuery,
  webpSrc,
} from './utils';
import { defaultImage } from './constants';
import { Image } from 'components/Image';

type UseLazyload = (props: {
  local: boolean;
  image: readonly (Image | null)[] | null;
}) => {
  defaultImage: string;
  img: string;
};

export const useLazyload: UseLazyload = ({ local, image }) => {
  const [img, setImg] = useState<string>('');

  const imageFormat = useCallback(
    async (
      supportedFormat: string,
      quality?: string | null,
      src?: string | null,
    ) => {
      switch (supportedFormat) {
        case 'avif': {
          // Chrome, Firefox, Android, Safari / iOS >= 16
          return getExtension(src) === 'svg'
            ? setImg(src || '')
            : setImg(avifSrc(local, quality || '', src || ''));
        }
        case 'webp': {
          // Safari / iOS < 16 && >= 14, Edge
          return getExtension(src) === 'svg'
            ? setImg(src || '')
            : setImg(webpSrc(local, quality || '', src || ''));
        }
        default: {
          return setImg(src || '');
        }
      }
    },
    [local],
  );

  const responsiveImage = useCallback(
    (supportedFormat: string) => {
      if (image && image.length === 1) {
        if (image[0]?.mediaQuery) {
          mediaQuery(image[0]?.mediaQuery)
            ? imageFormat(supportedFormat, image[0].quality, image[0].url)
            : setImg(defaultImage);
        } else {
          imageFormat(supportedFormat, image[0]?.quality, image[0]?.url);
        }
      }
      if (image && image.length > 1) {
        const noMediaQuery = image.every(img => img?.mediaQuery === undefined);
        if (noMediaQuery) {
          setImg(defaultImage);
        } else {
          image.some(img => {
            if (img?.mediaQuery && mediaQuery(img.mediaQuery)) {
              return imageFormat(supportedFormat, img.quality, img.url);
            } else {
              return setImg(defaultImage);
            }
          });
        }
      }
    },
    [imageFormat, image],
  );

  useEffect(() => {
    (async () => {
      const supportedFormat = await imageSupported();

      if (supportedFormat) {
        responsiveImage(supportedFormat);
        ['resize', 'orientationchange'].forEach(event =>
          window.addEventListener(event, () =>
            responsiveImage(supportedFormat),
          ),
        );
        return () => {
          ['resize', 'orientationchange'].forEach(event =>
            window.removeEventListener(event, () =>
              responsiveImage(supportedFormat),
            ),
          );
        };
      }
    })();
  }, [responsiveImage]);

  return {
    defaultImage,
    img,
  };
};
