import React from 'react';

import { LinkColour } from 'components/Hyperlink';
import PageLink from 'components/Hyperlink/PageLink';

import './styles.scss';

export type CareersItemProps = {
  excerpt?: string | null;
  slug?: string | null;
  title?: string | null;
};
const CareersItem: React.FunctionComponent<CareersItemProps> = ({
  excerpt,
  slug,
  title,
}) => {
  return (
    <article className="careers-item">
      {title && <h2>{title}</h2>}
      {excerpt && <p>{excerpt}</p>}
      {slug && (
        <PageLink colour={LinkColour.RED} slug={slug}>
          Find out more
        </PageLink>
      )}
    </article>
  );
};

export default CareersItem;
