/* Check If Image Exists */
export const imageExists = (src: string) =>
  fetch(src, { method: 'HEAD' }).then(res => {
    if (res.ok) {
      return res.url;
    } else {
      return false;
    }
  });

/* Check If Browser Supports Image Type */
export const imageSupported = () => {
  return new Promise<string>((resolve, reject) => {
    const avif = new Image();
    avif.src =
      'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAABoAAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAEAAAABAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQAMAAAAABNjb2xybmNseAACAAIABoAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACJtZGF0EgAKCBgABogQEDQgMgwYQAAAUAAAALASmkg=';
    avif.onload = () => resolve('avif');
    avif.onerror = () => {
      const webp = new Image();
      webp.src =
        'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAgA0JaQAA3AA/vuUAAA=';
      webp.onload = () => resolve('webp');
      webp.onerror = () => reject('');
    };
  });
};

export const avifSrc = (local: boolean, quality: string, src: string) =>
  local
    ? src?.split('.').slice(0, -1).join('.').concat('.avif')
    : `${src}${parseQuality(local, quality)}&fm=avif`;

export const webpSrc = (local: boolean, quality: string, src: string) =>
  local
    ? src?.split('.').slice(0, -1).join('.').concat('.webp')
    : `${src}${parseQuality(local, quality)}&fm=webp`;

export const mediaQuery = (media: string) => window.matchMedia(media).matches;

export const getExtension = (src?: string | null) => {
  return src?.split('.')[src.split('.').length - 1];
};

export const getFormat = (
  nextgenFormat: string,
  local?: boolean,
  source?: string,
  quality?: string | null,
) => {
  return local
    ? source?.split('.').slice(0, -1).join('.').concat(`.${nextgenFormat}`)
    : `${source}${parseQuality(local, quality)}&fm=${nextgenFormat}`;
};

export const parseQuality = (local?: boolean, quality?: string | null) => {
  if (local) {
    return '';
  } else {
    if (Number(quality) && Number(quality) > 0 && Number(quality) <= 100) {
      return `?q=${quality}`;
    } else {
      return '?q=60';
    }
  }
};
