import React from 'react';

import CareersSection from 'components/CareersSection';
import Hero from 'components/Hero';
import Main from 'components/Main';

type TemplateProps = Queries.CareersQuery;

const Template: React.FunctionComponent<TemplateProps> = ({
  contentfulCareers: props,
  allContentfulCareer,
}) => {
  return (
    <Main>
      <Hero {...props?.heroReference} />
      <CareersSection {...props} {...allContentfulCareer} />
    </Main>
  );
};

export default React.memo(Template);
