import React, { useRef } from 'react';
import clsx from 'clsx';

import { defaultImage, parseQuality } from 'modules/lazyload';
import { Image } from '..';
import { useIntersectionObserver } from 'modules/hooks/useIntersectionObserver';
import Source from './Source';

export type PictureProps = {
  alt?: string | null;
  children?: React.ReactNode;
  className?: string;
  lazyload?: boolean;
  local?: boolean;
  image?: readonly (Image | null)[] | null;
};

export const Picture = React.memo<PictureProps>(
  ({ alt, children, className, lazyload = false, local, image }) => {
    const elementRef = useRef<HTMLPictureElement>(null);
    const inView = useIntersectionObserver(elementRef, {
      root: null,
      rootMargin: '50px 0px',
      threshold: 0,
    });

    const classNames = clsx(
      lazyload && 'lazyload',
      lazyload && inView && 'lazyloaded',
      className,
    );
    return image ? (
      <picture className={classNames} ref={elementRef}>
        {image.map((i, index, key) => {
          return (
            <Source
              key={`${key}-${i?.url}-${index}`}
              lazyload={!lazyload || inView}
              local={local}
              media={i?.mediaQuery || undefined}
              quality={i?.quality}
              srcSet={i?.url || undefined}
            />
          );
        })}
        {children}
        <img
          alt={alt || ''}
          src={
            !lazyload || inView
              ? `${image[0]?.url}${parseQuality(local, image[0]?.quality)}`
              : defaultImage
          }
        />
      </picture>
    ) : null;
  },
);
