export enum LinkSize {
  LARGE = 'large',
  SMALL = 'small',
}

export enum LinkColour {
  BLUE = 'blue',
  DARK_BLUE = 'dark-blue',
  RED = 'red',
}
