import React, { useMemo, useRef } from 'react';
import clsx from 'clsx';

import { Image } from '..';
import { useLazyload } from 'modules/lazyload';
import { useIntersectionObserver } from 'modules/hooks/useIntersectionObserver';

export type LazyloadBackgroundProps = {
  children?: React.ReactNode;
  className?: string;
  local: boolean;
  image: readonly (Image | null)[] | null;
  style?: React.CSSProperties;
  tag?: React.ComponentType | keyof JSX.IntrinsicElements;
};

export const LazyloadBackground: React.FunctionComponent<
  LazyloadBackgroundProps
> = ({ children, className, image, local, style, tag = 'div' }) => {
  const elementRef = useRef<HTMLElement>(null);
  /* eslint-disable-next-line */
  const Tag: any = useMemo(() => tag, [tag]);
  const inView = useIntersectionObserver(elementRef, {
    root: null,
    rootMargin: '50px 0px',
    threshold: 0,
  });

  const { defaultImage, img } = useLazyload({
    image,
    local,
  });

  const classNames = clsx('lazyload', inView && 'lazyloaded', className);

  return (
    <Tag
      className={classNames}
      ref={elementRef}
      style={{
        backgroundImage:
          inView && img !== defaultImage ? `url(${img})` : undefined,
        ...style,
      }}>
      {children}
    </Tag>
  );
};
