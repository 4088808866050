import React, { useRef } from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';

import { LinkSize, LinkColour } from '../constants';
import { useActiveAnimation, useHandlers, useTabIndex } from '../hooks';
import Spinner from 'modules/theme/images/button/spinner.svg';

import 'components/Button/styles.scss';

export type PageLinkProps = {
  ariaLabel?: string;
  children?: React.ReactNode;
  className?: string;
  colour?: LinkColour.RED | LinkColour.BLUE | LinkColour.DARK_BLUE;
  cssStyle?: React.CSSProperties;
  disabled?: boolean;
  forwardedRef?: React.RefObject<HTMLAnchorElement>;
  label?: string;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  pathname?: string;
  size?: LinkSize.LARGE | LinkSize.SMALL;
  slug: string;
  tabIndex?: number;
  title?: string;
};

const PageLink: React.FunctionComponent<PageLinkProps> = ({
  ariaLabel,
  children,
  className,
  colour,
  cssStyle,
  disabled,
  forwardedRef,
  label,
  loading,
  onClick,
  pathname,
  slug,
  size = LinkSize.LARGE,
  tabIndex,
  title,
}) => {
  const ref = useRef<HTMLAnchorElement>(null);
  const { animation, linkRef, handleActiveAnimation, handleAnimationEnd } =
    useActiveAnimation(forwardedRef || ref);
  const { handleClick, handleKeyDown, handleMouseDown } = useHandlers(
    handleActiveAnimation,
    disabled || loading,
    onClick,
  );
  const tabindex = useTabIndex(disabled || loading, tabIndex);

  const classNames = clsx(
    animation && 'active-animation',
    (disabled || loading) && 'disabled',
    colour && 'button',
    colour && `${colour}`,
    colour && `${size}`,
    loading && 'loading',
    className,
  );

  return (
    <Link
      aria-label={ariaLabel || label}
      className={classNames || undefined}
      innerRef={linkRef}
      onAnimationEnd={handleAnimationEnd}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onMouseDown={handleMouseDown}
      state={{ prevPath: pathname }}
      style={cssStyle}
      tabIndex={tabindex}
      title={title || ariaLabel}
      to={slug}>
      {loading && <Spinner />}
      {children}
      {label && <span>{label}</span>}
    </Link>
  );
};

export default React.memo(PageLink);
