import { useCallback, useState } from 'react';

export const useActiveAnimation = (ref: React.RefObject<HTMLAnchorElement>) => {
  const [animation, setAnimation] = useState(false);

  const getAnimationDuration = (element: HTMLAnchorElement) => {
    return (
      window
        .getComputedStyle(element, null)
        .getPropertyValue('animation-duration') !== '0s'
    );
  };

  const handleActiveAnimation = useCallback(
    (
      event:
        | React.KeyboardEvent<HTMLAnchorElement>
        | React.MouseEvent<HTMLAnchorElement>,
    ) => {
      if (
        event.currentTarget === ref.current &&
        getAnimationDuration(ref.current)
      ) {
        setAnimation(true);
      }
    },
    [ref],
  );

  const handleAnimationEnd = useCallback(() => setAnimation(false), []);

  return {
    animation,
    handleActiveAnimation,
    handleAnimationEnd,
    linkRef: ref,
  } as const;
};
