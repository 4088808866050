import React, { useRef } from 'react';
import clsx from 'clsx';

import { Image } from '..';
import { useLazyload } from 'modules/lazyload';
import { useIntersectionObserver } from 'modules/hooks/useIntersectionObserver';

export type LazyloadImgProps = {
  alt: string;
  className?: string;
  local: boolean;
  image: readonly (Image | null)[] | null;
};

export const LazyloadImg = React.memo<LazyloadImgProps>(
  ({ alt, className, local, image }) => {
    const elementRef = useRef<HTMLImageElement>(null);

    const inView = useIntersectionObserver(elementRef, {
      root: null,
      rootMargin: '50px 0px',
      threshold: 0,
    });

    const { defaultImage, img } = useLazyload({
      local,
      image,
    });

    const classNames = clsx('lazyload', inView && 'lazyloaded', className);
    return (
      <img
        alt={alt}
        className={classNames}
        ref={elementRef}
        src={inView ? img : defaultImage}
      />
    );
  },
);
